import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { confirmDialog } from 'primereact/confirmdialog';
import { Divider } from 'primereact/divider';
import { Nullable } from 'primereact/ts-helpers';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { object } from 'yup';
import {
  AttributeStructureGroup,
  AttributeStructureItem,
  AttributeValueAttributeTypeEnum,
  ProductChangeRequest,
  ProductChangeResponse,
  ValidationErrors,
} from '../../api/_generated_';
import useValidateProductChange from '../../hooks/useValidateProductChange';
import {
  clearSubFields,
  getDefaultFormValues,
  getDisplayValue,
  getDownloadUrl,
  getFlatAttributes,
  getStructureItemLabel,
} from '../../utils/attribute';
import { getLangByI18nLocale } from '../../utils/helper';
import DataRow from '../DataRow';
import DifferenceList from '../DifferenceList';
import DynamicField from '../DynamicField';
import './ProductChangeEditor.scss';
import { AttributeConfigContext } from '../../utils/AttributeConfigProvider';
import { EAN_CODE, PHOTOGRAPHY_SERVICE, VINTAGE } from '../../constants/pim-attributes';
import { DATE_FORMAT_PRIME } from '../../i18n/config';

type Props = {
  productId?: string;
  data?: ProductChangeResponse;
  isLoading?: boolean;
  isEditing?: boolean;
  onSubmit(changes: ProductChangeRequest): void;
};

function getAnalysisFields(sections: Array<AttributeStructureGroup>): string[] {
  const section = sections.find((s) => s.code === 'Analyysitiedot');
  if (section) {
    return section.attributes.filter((attr) => attr.code !== 'TarAnalyysiTod').map((attr) => attr.code);
  }
  return [];
}

function createYupSchema(defaultValues: { [key: string]: object; }, sections: Array<AttributeStructureGroup>, t: TFunction<'translation', undefined>) {
  const analysisFields = getAnalysisFields(sections);
  return object().shape({
    TarAnalyysiTod: yup.string().when(analysisFields, (fields, schema) => {
      const modifiedFields = analysisFields
        .filter((field, key) => fields[key] !== defaultValues[field]);
      return modifiedFields.length === 0
        ? schema.optional().nullable()
        : schema.required(t('validations.message-validation-error-analsysis_certificate_is_required'));
    }),
  });
}

export default function ProductChangeEditor({
  productId,
  data,
  isLoading,
  isEditing,
  onSubmit,
}: Props) {
  const { isAttributeVisible } = useContext(AttributeConfigContext);

  const [editIndex, setEditIndex] = useState<number | undefined>();

  const [changes, setChanges] = useState<{ [key: string]: object; }>(data?.proposals || {});

  const [effective, setEffective] = useState<Nullable<string | Date | Date[]>>(null);

  const { t, i18n } = useTranslation();

  const flatAttributes = useMemo(() => getFlatAttributes(data?.sections), [data]);

  const originalValues = useMemo(
    () => getDefaultFormValues(data?.values, flatAttributes),
    [flatAttributes, data],
  );

  const {
    control,
    watch,
    setValue,
    resetField,
    getValues,
    setError,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(createYupSchema(originalValues, data?.sections || [], t)) as any,
    defaultValues: {
      ...originalValues,
      productionPlants: data?.values?.productionPlants || [],
    } as Record<string, any>,
  });

  const validation = useValidateProductChange({
    onSuccess: (_, variables) => {
      setChanges(variables.body);
      setEditIndex(undefined);
    },
    onValidationError: (result: ValidationErrors) => {
      Object.keys(result.errors).forEach((key) => {
        setError(key, {
          type: 'server',
          message: t(result.errors[key].message, {
            value: result.errors[key].params,
            ...result.errors[key].options,
          }),
        });
      });
    },
  });

  const getUploadUrl = (attributeCode: string) => `/files?useAttributeValidation=true&attribute=${attributeCode}`;

  useEffect(() => {
    setChanges({ ...data?.proposals });

    const effectiveDate = data?.effective ? dayjs(data?.effective).toDate() : null;
    setEffective(effectiveDate);

    Object.keys(getValues()).forEach((key) => {
      if (data?.proposals?.[key] !== undefined) {
        setValue(key, data?.proposals?.[key]);
      }
    });
  }, [data, getValues, setValue]);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      clearSubFields(setValue, name);
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const beforeUnLoad = useCallback((e: BeforeUnloadEvent) => {
    if (Object.keys(changes).length > 0) {
      e.preventDefault();
      e.returnValue = true;
    }
  }, [changes]);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnLoad);
    return () => {
      window.removeEventListener('beforeunload', beforeUnLoad);
    };
  }, [beforeUnLoad]);

  const confirmMessage = (changesToConfirm: { [key: string]: object }) => (
    <>
      <p>{t('products.label-confirm-product-changes-description')}</p>
      {changesToConfirm[EAN_CODE] && changesToConfirm[VINTAGE] && <p>{t('products.label-confirm-product-changes-vintage-variant')}</p>}
    </>
  );

  const onConfirm = () => {
    confirmDialog({
      header: t('products.label-confirm-product-changes'),
      message: confirmMessage(changes),
      icon: 'pi pi-info-circle',
      acceptLabel: t('common.label-yes'),
      rejectLabel: t('common.label-no'),
      accept: () => onSubmit({
        body: changes,
        effective: dayjs(effective as Date).format('YYYY-MM-DD'),
      }),
    });
  };

  const onFinishEditing = (formData: { [key: string]: object; }) => {
    const changedValues: { [key: string]: object; } = {};

    Object.keys(originalValues).forEach((key) => {
      const original = originalValues[key] ? originalValues[key].toString() : '';
      const newValue = formData[key] ? formData[key].toString() : '';

      if (original !== newValue) {
        changedValues[key] = formData[key];
      }
    });

    if (Object.keys(changedValues).length > 0) {
      validation.mutate({
        body: changedValues,
        effective: effective
          ? dayjs(effective as Date).format('YYYY-MM-DD')
          : dayjs().startOf('day').format('YYYY-MM-DD'),
      });
    } else {
      setEditIndex(undefined);
    }
  };

  const onRevertChange = (property: string) => {
    const filtered = { ...changes };
    delete filtered[property];

    setChanges(filtered);
    resetField(property);
  };

  const isEditModeActive = (i: number | undefined) => i !== null && i !== undefined;

  const isEditModeDisabled = (i: number | undefined) => i === null || i === undefined;

  const isSendForApprovalDisabled = () => !Object.keys(changes || {}).length
    || isEditModeActive(editIndex)
    || effective === null;

  function renderGroup(group: AttributeStructureGroup, i: number) {
    const filteredAttributes = group.attributes?.filter((attr) => {
      if (attr.code === PHOTOGRAPHY_SERVICE && originalValues[attr.code]) {
        return false;
      }

      return !attr.readOnly && (isEditing || isAttributeVisible(attr.code, watch()));
    });

    if (filteredAttributes.length === 0) {
      return null;
    }

    const editing = editIndex === i;

    return (
      <div
        key={`tab-${group.code}`}
        className={classNames({
          'bg-white border-surface border-round-xl mb-3': true,
          'opacity-70': isEditModeActive(editIndex) && !editing,
        })}
      >
        <div className="section-header">
          <span className="text-lg">{group.translation}</span>
          <div className="flex gap-2">
            {editing && (
              <Button
                rounded
                icon="pi pi-check"
                severity="danger"
                loading={validation.isLoading}
                onClick={handleSubmit((formData) => onFinishEditing(formData))}
              />
            )}
            {isEditModeDisabled(editIndex)
              && (
                <Button
                  text
                  size="small"
                  rounded
                  severity="secondary"
                  icon="pi pi-pencil"
                  disabled={isLoading}
                  onClick={() => setEditIndex(i)}
                />
              )}
          </div>
        </div>
        <div className="py-4 px-5">
          {renderGroupAttributes(filteredAttributes, editing)}
        </div>
      </div>
    );
  }

  function renderGroupAttributes(attribures: AttributeStructureItem[], editing: boolean) {
    if (editing) {
      return attribures.map((attribute) => (
        <DynamicField
          key={`field-${attribute.code}`}
          control={control}
          watch={watch}
          required={false}
          attribute={attribute}
          downloadUrl={() => getDownloadUrl(
            watch(attribute.code) as unknown as string,
            attribute.code,
            productId,
          )}
          uploadUrl={getUploadUrl(attribute.code)}
        >
          <Button
            icon="pi pi-undo"
            severity="info"
            className="addon"
            onClick={() => onRevertChange(attribute.code)}
          />
        </DynamicField>
      ));
    }

    return attribures.map((attribute) => (
      <DataRow
        key={`attribute-${attribute.code}`}
        isImage={attribute.attributeType === AttributeValueAttributeTypeEnum.Image}
        isFile={attribute.attributeType === AttributeValueAttributeTypeEnum.File}
        label={getStructureItemLabel(attribute)}
        value={getDisplayValue(getValues(), attribute, t)}
        downloadUrl={getDownloadUrl(
          getValues(attribute.code) as unknown as string,
          attribute.code,
          productId,
        )}
        className="col-12 md:col-6 mb-2"
      />
    ));
  }

  return (
    <div className="product-change-editor">
      <div className="flex justify-content-between flex-wrap">
        <div className="flex flex align-self-center align-items-center justify-content-center">
          <h4 className="font-normal">{t('products.title-product-information')}</h4>
        </div>
        <div className="flex align-self-center align-items-center justify-content-center mb-3">
          <Button
            label={t('products.label-send-to-approval')}
            onClick={onConfirm}
            disabled={isSendForApprovalDisabled()}
            loading={isLoading}
          />
        </div>
      </div>
      <div>
        {data?.sections?.map((group, i) => renderGroup(group, i))}
      </div>
      <Divider />

      <h4 className="font-normal">{t('common.label-edited-records')}</h4>
      <DifferenceList
        sections={data?.sections}
        changes={changes}
        current={originalValues || {}}
      />

      <Divider />

      <div className="bg-white border-surface border-round-xl mb-5">
        <div className="section-header">
          <label htmlFor="effective" className={classNames({ 'text-lg mb-0': true, block: true, 'p-error': !effective })}>{t('products.label-changes-effective-since')}</label>
        </div>
        <div className="py-4 px-5">
          <Calendar
            inputId="effective"
            name="effective"
            locale={getLangByI18nLocale(i18n.language)}
            dateFormat={DATE_FORMAT_PRIME}
            value={effective}
            minDate={new Date()}
            onChange={(e) => setEffective(e.value)}
            style={{ width: '300px' }}
            showButtonBar
            showIcon
          />
        </div>
      </div>

      <div className="text-right">
        <Button
          label={t('products.label-send-to-approval')}
          onClick={onConfirm}
          disabled={isSendForApprovalDisabled()}
          loading={isLoading}
        />
      </div>
    </div>
  );
}
