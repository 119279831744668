import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from './useAxiosPrivate';
import { PriceQueryViewData } from '../api/_generated_';

export default function usePriceQueryViewData() {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  return useQuery({
    queryKey: ['priceQueryViewData', i18n.language],
    queryFn: async (): Promise<PriceQueryViewData> => {
      const response = await axios.get(`/price-query/attributes?locale=${i18n.language}`);
      return response.data;
    },
  });
}
