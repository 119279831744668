import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  AttributeStructureItem, PriceData, PriceDataParams, PriceQueryViewData,
} from '../../api/_generated_';
import DynamicField from '../DynamicField';
import { formDataToPriceDataParams, getDefaultFormValues, isPriceCalculationDisabled } from '../../utils/attribute';
import { getLangByI18nLocale } from '../../utils/helper';
import { DATE_FORMAT_PRIME } from '../../i18n/config';
import PriceQueryResults from './PriceQueryResults';
import { ALCOHOL_CONTENT } from '../../constants/pim-attributes';

type Props = {
  data: PriceQueryViewData;
  results: PriceData | undefined;
  isLoading?: boolean;
  onSubmit: SubmitHandler<PriceDataParams>;
};

type KeyValuePair = {
  [key: string]: any;
};

export default function PriceQueryForm({
  data, results, isLoading, onSubmit,
}: Props) {
  const { t, i18n } = useTranslation();

  const { control, watch, handleSubmit } = useForm<KeyValuePair>({
    defaultValues: getDefaultFormValues({}, data?.priceQueryAttributes),
  });

  const onFormSubmit = (formData: KeyValuePair) => {
    let startDate = dayjs().add(1, 'month').format('YYYY-MM-DD');
    if (formData.date) {
      startDate = dayjs(formData.date).format('YYYY-MM-DD');
    }

    const body: PriceDataParams = formDataToPriceDataParams(
      startDate,
      formData,
    );

    onSubmit(body);
  };

  const getSpecialRule = (attribute: AttributeStructureItem) => {
    if (attribute.code === ALCOHOL_CONTENT) {
      return {
        validate: () => {
          if (isPriceCalculationDisabled(watch(), data.ethylMildLimit, data.mildAlcoholLimit)) {
            return t('validations.message-price-calculation-alcohol-content-limits');
          }

          return undefined;
        },
      };
    }

    return undefined;
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {data?.priceQueryAttributes?.map((item) => (
        <DynamicField
          required
          key={item.code}
          control={control as any}
          attribute={item}
          name={item.code}
          watch={watch}
          rules={getSpecialRule(item)}
        />
      ))}
      <Controller
        control={control}
        name="date"
        render={({ field }) => (
          <div className="field">
            <label htmlFor={field.name}>
              {t('price-query.label-selection-date')}
            </label>
            <Calendar
              className="w-full mb-3"
              value={field.value}
              name={field.name}
              showIcon
              onChange={(e) => field.onChange(e.value)}
              locale={getLangByI18nLocale(i18n.language)}
              dateFormat={DATE_FORMAT_PRIME}
            />
          </div>
        )}
      />
      <PriceQueryResults data={results} isLoading={isLoading} />
    </form>
  );
}
