import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from './useAxiosPrivate';
import { PriceData, PriceDataParams } from '../api/_generated_';
import useUIStore from '../stores/UIStore';

export default function usePriceQuery() {
  const { t } = useTranslation();

  const axios = useAxiosPrivate();

  const { toast } = useUIStore();

  return useMutation({
    mutationFn: async (body: PriceDataParams): Promise<PriceData> => {
      const res = await axios.post('/price-query', body);
      return res.data;
    },
    onError: () => toast.current?.show({
      severity: 'error',
      summary: t('prices.message-price-calculation-failed'),
    }),
  });
}
