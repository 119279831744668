/* 
* Automatically generated by cra-envs.
* If you wish to declare a new environment variable declare it in the .env file (prefixed by REACT_APP_)
* then run 'npx generate-env-getter' at the root of your project.
* This file will be updated.
*/
import { getEnvVarValue } from "cra-envs";

export const envNames = [
    "SSO_CLIENT_ID",
    "SSO_TENANT_ID",
    "SUPPLIERPORTAL_BASE_PATH",
    "SSO_SCOPES",
    "SUPPLIER_USER_MANUAL_LINK",
    "CODE_OF_CONDUCT_LINK",
    "SELECTION_GUIDE_LINK",
    "FEATURE_FLAG_PRICE_QUERY"
] as const;

export type EnvName = typeof envNames[number];

let env: Record<EnvName, string> | undefined = undefined;

export function getEnv() {

    if (env === undefined) {
        env = {} as Record<EnvName, string>;
        for (const envName of envNames) {
            env[envName] = getEnvVarValue(envName);
        }
    }

    return env;

}
