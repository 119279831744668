import { useTranslation } from 'react-i18next';
import usePriceQueryViewData from '../../hooks/usePriceQueryViewData';
import NetworkContainer from '../../components/NetworkContainer';
import { HttpRequestStatus } from '../../components/types/types';
import PriceQueryForm from '../../components/PriceQueryForm';
import usePriceQuery from '../../hooks/usePriceQuery';
import { PriceDataParams } from '../../api/_generated_';
import ViewHeader from '../../components/ViewHeader';
import PricingFactorChangeInfo from '../../components/PricingFactorChangeInfo';

export default function PriceQuery() {
  const { t } = useTranslation();

  const { data: viewData, status, isSuccess } = usePriceQueryViewData();

  const { mutate, data: priceResult, isLoading } = usePriceQuery();

  const calculatePrice = (data: PriceDataParams) => {
    mutate(data);
  };

  return (
    <NetworkContainer status={status as HttpRequestStatus}>
      <ViewHeader title={t('price-query.title-price-query')} />
      {isSuccess && (
        <>
          <PricingFactorChangeInfo infoTexts={viewData.infoTexts} />
          <PriceQueryForm
            data={viewData}
            results={priceResult}
            isLoading={isLoading}
            onSubmit={calculatePrice}
          />
        </>
      )}
    </NetworkContainer>
  );
}
