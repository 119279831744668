/* eslint-disable react/no-array-index-key */
import { Message } from 'primereact/message';
import React from 'react';

type Props = {
  infoTexts: string[];
};

export default function PricingFactorChangeInfo({ infoTexts }: Props) {
  if (!infoTexts?.length) {
    return null;
  }

  return (
    <div className="flex gap-3 flex-column mb-5">
      {infoTexts.map((infoText, index) => (
        <Message key={`message-${index}`} severity="info" text={infoText} className="w-full" />
      ))}
    </div>
  );
}
